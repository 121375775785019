import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { GhostAuthor } from 'types/ghost'
import Layout from 'components/Layout'
import { centeredContent, typography } from 'styles'
import SiteHeader from 'components/Header'
import PostPreviewWithImage from 'components/PostPreviewWithImage'
import FeaturedPost from 'components/FeaturedPost'
import AuthorImage from 'components/AuthorImage'
import getMostRecentFeaturedPost from 'lib/getMostRecentFeaturedPost'
import PostPreviewGrid from 'components/PostPreviewGrid'
import * as utils from 'lib/utils';

interface Props {
  data: {
    author: GhostAuthor
  }
}

const Header = styled.div({
  marginBottom: '3.2rem',
})

const AuthorTemplate = ({ data }: Props) => {
  const posts = data.author.posts.filter(post => utils.includePost(post.tags));

  const featuredPost = getMostRecentFeaturedPost(posts)
  const latestPosts = posts.filter(
    post => post !== featuredPost && !post.page
  )

  return (
    <Layout>
      <Helmet title={data.author.name}>
        <meta name="description" content={data.author.bio} />
      </Helmet>
      <SiteHeader />
      <div
        css={[
          centeredContent(1080),
          { marginTop: '3rem', marginBottom: '4.8rem' },
        ]}
      >
        <Header>
          <AuthorImage
            css={{ marginBottom: '0.8rem' }}
            src={data.author.profile_image && data.author.profile_image.src}
          />
          <h1 css={{ ...typography.headline1 }}>{data.author.name}</h1>
          {!!data.author.bio && (
            <p
              css={{
                ...typography.body,
                marginTop: '0.4rem',
                maxWidth: '60ch',
              }}
            >
              {data.author.bio}
            </p>
          )}
        </Header>
        {!!featuredPost && (
          <FeaturedPost css={{ marginBottom: '2rem' }} post={featuredPost} />
        )}
        {!!latestPosts.length && (
          <Fragment>
            <h2
              css={{
                ...typography.caption,
                fontWeight: 'bold',
                textTransform: 'uppercase',
                lineHeight: 1,
                borderBottom: '1px solid #dfe2e5',
                paddingBottom: '0.5rem',
                marginBottom: '0.75rem',
              }}
            >
              Latest
            </h2>
            <PostPreviewGrid>
              {latestPosts.map(post => (
                <PostPreviewWithImage key={post.id} post={post} />
              ))}
            </PostPreviewGrid>
          </Fragment>
        )}
      </div>
    </Layout>
  )
}

export default AuthorTemplate

export const query = graphql`
  query($ghostId: String!) {
    author: ghostAuthor(ghostId: { eq: $ghostId }) {
      name
      bio
      profile_image {
        src
      }
      posts {
        ...ghostPostDetails
        featured
        page
      }
    }
  }
`
