import React, { DetailedHTMLProps, ImgHTMLAttributes } from 'react'
import { colors } from 'styles'
import peLogo from 'images/pe-logo-white.svg'

const AuthorImage = ({
  src,
  ...props
}: DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>) => (
  <img
    css={{
      height: '75px',
      width: '75px',
      borderRadius: '50%',
      objectFit: 'cover',
      backgroundColor: !src && colors.primary,
    }}
    src={src || peLogo}
    {...props}
  />
)

export default AuthorImage
